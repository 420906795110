import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware(async (to) => {
  const workflowStore = useWorkflowStore()
  const { workflow } = storeToRefs(workflowStore)

  const workflowStepId = to.params.step_id as string

  if (!workflow.value)
    await workflowStore.get()

  const exists = await workflowStore.workflowStepExists(workflowStepId)

  if (!exists)
    return abortNavigation()
})
